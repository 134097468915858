<template>
  <div class="w-10/12">
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="mt-6">
      <div class="w-full">
        <p class="font-bold mb-7 text-lg inline-block">Employment Details</p>
        <span v-if="!cannotEdit" class="float-right cursor-pointer text-flame text-base font-semibold">
          <span @click="handleClick" v-if="!edit">
            <icon icon-name="icon-edit" size="xs" class="inline-block" />
            <span class="pl-2">Edit</span>
          </span>
          <span @click="handleSave" v-else>
            <icon icon-name="save" size="xs" class="inline-block" />
            <span class="pl-2">Save</span>
          </span>
        </span>
      </div>
      <div class="grid grid-cols-12 gap-6 text-darkPurple" style="margin-top: 1.125rem">
        <div v-if="
          !isBasic
        " class="col-span-3">
          <c-text placeholder="--Enter--" variant="w-full" label="Employee ID" v-model="employment.employeeId"
            :disabled="!edit" />
        </div>
        <div class="col-span-3" :class="isBasic
            ? 'order-1'
            : ''
          ">
          <c-select placeholder="--Enter--" variant="w-full h-10" label="Employment Type" :options="employmentTypes"
            v-model="employment.employmentType" :disabled="!edit" />
        </div>
        <div class="col-span-3" :class="isBasic
            ? 'order-2'
            : ''
          ">
          <label class="date-label">Engagement Date</label>
          <div :class="!edit ? 'date-flex_disabled' : 'date-flex'">
            <datepicker placeholder="--Enter--" :input-class="!edit ? 'date-input date-input_disabled' : 'date-input'
              " style="width: 100%; outline: none" v-model="employment.resumptionDate" :disabled="!edit" />
          </div>
        </div>
        <div class="col-span-3" :class="isBasic
            ? 'order-3'
            : ''
          ">
          <label class="date-label"><span>Effective Payroll Date</span>
            <span>
              <Icon v-tooltip.top="`When employee is added to pay run or have been paid you can't edit`
                " class-name="text-darkPurple h-3 w-1 flex cursor-pointer" icon-name="tooltip" size="xsm" />
            </span>
          </label>
          <div :class="!edit ? 'date-flex_disabled' : 'date-flex'">
            <datepicker placeholder="--Enter--" :input-class="!edit || employment.disableEmploymentDate
                ? 'date-input date-input_disabled'
                : 'date-input'
              " style="width: 100%; outline: none" v-model="employmentDate"
              :disabled="!edit || employment.disableEmploymentDate" />
          </div>
        </div>
        <div class="col-span-3" :class="isBasic
            ? 'order-8'
            : ''
          ">
          <c-select placeholder="--Enter--" :options="locationData" variant="w-full h-10" label="Office Location"
            v-model="employment.officeAddress" :disabled="!edit" />
        </div>
        <div class="col-span-3" :class="isBasic ? 'order-5' : ''">
          <label class="date-label flex">
            <span class="flex-1">{{ isBasic ? "Department" : "Function" }}</span>
            <span v-if="edit" v-show="isBasic || isStandard" @click="showFunctionModal = true"
              class="uppercase text-flame text-xs font-semibold cursor-pointer">
              Add New
            </span>
          </label>
          <v-select :disabled="!edit" :clearable="false" class="style-select" placeholder="--Enter--"
            :reduce="option => option.id" :options="setFunctions" v-model="globalFunctionId">
            <template #option="{ name, id }">
              <div @mouseenter="hoveredItem = id" @mouseleave="hoveredItem = null">
                <div class="hover:text-white flex justify-between items-center gap-4">
                  <span class=" text-base cursor-pointer whitespace-normal">{{ name }}</span>
                  <div v-if="isBasic || isStandard" class="flex justify-start items-center gap-2">
                    <span @click="newFunction = name, isEditFunction = true, showFunctionModal = true, functionId = id">
                      <icon icon-name="edit" size="xs"
                        :class-name="hoveredItem === id ? 'text-white' : 'text-blueCrayola'" />
                    </span>
                    <span @click.prevent="onDeleteFuncDesig('function')">
                      <icon icon-name="delete" size="xs"
                        :class-name="hoveredItem === id ? 'text-white' : 'text-flame'" />
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template #selected-option="{ name }">
              <span class="overflow-hidden">{{ name }}</span>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <icon icon-name="caret-down" size="xsm" />
              </span>
            </template>
          </v-select>
        </div>

        <div class="col-span-3" :class="isBasic ? 'order-6' : ''">
          <label class="date-label flex">
            <span class="flex-1">{{ isBasic ? "Job Title" : "Designation" }}</span>
            <span v-if="globalFunctionId !== null && edit" v-show="isBasic || isStandard"
              @click="showDesignationModal = true" class="uppercase text-flame text-xs font-semibold cursor-pointer">
              Add New
            </span>
          </label>
          <v-select :clearable="false" class="style-select" placeholder="--Enter--" :reduce="option => option.id"
            :options="setDesignation && setDesignation.designations" v-model="globalDesignation" :disabled="!edit"
            :selectable="(option) => !option.disabled">
            <template #option="item">
              <div @mouseenter="hoveredItem = item.id" @mouseleave="hoveredItem = null">
                <div :class="item.disabled ? 'hover:text-romanSilver' : 'hover:text-white'"
                  class="flex justify-between items-center gap-4">
                  <span class="font-bold text-base cursor-pointer whitespace-normal">{{ item.name }}</span>
                  <div v-if="isBasic || isStandard" class="flex justify-start items-center gap-2">
                    <span @click="onModifyDesignation(item)">
                      <icon icon-name="edit" size="xs"
                        :class-name="hoveredItem === item.id ? 'text-white' : 'text-blueCrayola'" />
                    </span>
                    <span @click="onDeleteFuncDesig('designation')">
                      <icon icon-name="delete" size="xs"
                        :class-name="hoveredItem === item.id ? 'text-white' : 'text-flame'" />
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template #selected-option="{ name }">
              <span class="overflow-hidden">{{ name }}</span>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <icon icon-name="caret-down" size="xsm" />
              </span>
            </template>
          </v-select>
        </div>
        <div v-if="
          !isBasic && !isStandard
        " class="col-span-3">
          <div class="text-sm mb-1 text-jet">Job Level</div>
          <div :class="!edit ? 'disabled disabled_hideOutline' : 'disabled'">
            {{ setLevel ? setLevel.name : "" }}
          </div>
        </div>
        <div class="col-span-3" :class="isBasic
            ? 'order-7'
            : ''
          ">
          <label class="date-label flex justify-start gap-1">
            <span>1<sup>st</sup></span>
            <span>Line Reporting</span>
          </label>
          <div :class="!edit ? 'disabled disabled_hideOutline' : 'disabled'">
            {{ setReportingTo ? `${setReportingTo.fname} ${setReportingTo.lname}` : null }}
          </div>
        </div>
        <div v-if="
          !isBasic && !isStandard
        " class="col-span-3">
          <label class="date-label">Dotted Reporting</label>
          <v-select :clearable="false" placeholder="Search employees"
            :selectable="(option) => option.id !== $route.params.id" label="fname" class="style-select"
            :disabled="!edit" :reduce="(option) => option.id" v-model="employment.dottedReportingTo"
            @search="setAllOrgUsers" :options="allOrgUsers" :filterable="false">
            <template #option="{ fname, lname, orgFunction, userDesignation, photo }">
              <div class="flex">
                <span class="mt-2">
                  <img class="" :src="photo" v-if="photo" alt="profile pic"
                    style="height: 25px; width: 25px; border-radius: 5px" />
                  <div style="height: 25px; width: 25px; border-radius: 5px"
                    class="text-blueCrayola text-xs border text-center font-semibold pt-1" v-else>
                    {{ $getInitials(`${fname} ${lname}`) }}
                  </div>
                </span>
                <div class="ml-2 hover:text-white">
                  <div class="font-bold mt-1 text-sm cursor-pointer">
                    {{ fname }} {{ lname }}
                  </div>
                  <div class="" style="font-size: 10px">
                    <span class="uppercase">{{
                      orgFunction ? orgFunction.name : ""
                    }}</span>
                    <span class="ml-2">{{
                      userDesignation ? `${userDesignation.name}` : ""
                    }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template #selected-option="{ fname, lname }">
              {{ fname }} {{ lname }}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <icon icon-name="caret-down" size="xsm" />
              </span>
            </template>
            <template #list-footer>
              <li style="text-align: center; font-size: 12px">
                Type name of employee to search more
              </li>
            </template>
          </v-select>
        </div>
        <div class="col-span-3" :class="isBasic
            ? 'order-4'
            : ''
          ">
          <c-select placeholder="--Enter--" variant="w-full h-10" :options="workModelOptions"
            @onChange="handleWorkModelChange" label="Work Model" v-model="employment.workModel" :disabled="!edit" />
        </div>
        <div v-if="
          !isBasic
        " class="col-span-3">
          <label class="date-label flex justify-between"><span>Eligible for Overtime</span>
            <span>
              <Icon class-name="text-flame h-3 w-1 flex cursor-pointer" v-if="edit" icon-name="settings"
                @click.native="openSettingsModal = true" size="xsm" />
            </span>
          </label>
          <c-select placeholder="--Enter--" variant="w-full h-10" :options="overtimeOptions"
            v-model="employment.enabledOvertime" :disabled="!edit" />
        </div>
        <div v-if="
          !isBasic
        " class="col-span-3">
          <c-select placeholder="--Enter--" variant="w-full h-10" :options="primaryStatus" label="Primary Status"
            v-model="employment.isActive" :disabled="!edit" />
        </div>
        <div v-if="
          !isBasic
        " class="col-span-3">
          <div class="text-sm mb-1 flex justify-between capitalize text-jet">
            <p>Secondary Status</p>
            <h6 @click="showConfirmationModal = true" class="text-desire cursor-pointer" v-if="
              (employment.employmentStatus === 'confirmed' ||
                employment.employmentStatus === null) &&
              edit
            ">
              Confirm
            </h6>
          </div>
          <div class="capitalize" :class="!edit ? 'disabled disabled_hideOutline' : 'disabled'">
            {{ employment.employmentStatus ? employment.employmentStatus : "" }}
          </div>
        </div>
      </div>
      <div v-if="
        $store.state.subscription &&
        $store.state.subscription.plan === 'basic'
      ">
        <divider class="my-8" />
        <div>
          <p class="font-bold text-lg mb-7">Pay Info</p>
          <div class="w-full pt-4 flex justify-between">
            <div>
              <div class="text-base flex text-jet">
                <p>Payroll Status:</p>
                <p class="ml-4" :class="payrollStatus === 'active'
                    ? ' text-mediumSeaGreen'
                    : 'text-flame'
                  " v-if="!edit">
                  {{ payrollStatus ? toTitleCase(payrollStatus) : "Inactive" }}
                </p>
              </div>
              <radio-button :options="statusOptions" v-if="edit" col-span="col-span-6" class="mt-4 text-darkPurple"
                v-model="payrollStatus" />
            </div>
          </div>
          <div class="flex my-7">
            <checkbox v-model="compensation.enabledPAYE" label="PAYE"
              checkbox-size="width:16px; height:16px; margin: 4px 8px 4px 0;" class="" :disabled="!edit" />
            <checkbox v-model="compensation.enabledPension"
              checkbox-size="width:16px; height:16px; margin: 4px 8px 4px 0;" label="Pension" class="ml-7"
              :disabled="!edit" />
            <checkbox v-model="compensation.enabledNHF" checkbox-size="width:16px; height:16px; margin: 4px 8px 4px 0;"
              class="ml-7" label="NHF" :disabled="!edit" />
          </div>
          <div class="grid-cols-12 grid w-full gap-y-7 gap-x-3 my-3 text-darkPurple" style="margin-top: 1.125rem">
            <div class="col-span-3">
              <div class="text-sm text-jet">Pay Type</div>
              <div class="wrapper h-10 mt-2 capitalize disabled" v-if="!edit">
                {{ payType }}
              </div>
              <c-select placeholder="--Select--" variant="w-full h-10" :options="[{ name: 'Salaried', id: 'salaried' }]"
                v-model="payType" v-else />
            </div>
            <div class="col-span-3">
              <div class="text-sm text-jet">Pay Frequency</div>
              <div class="wrapper h-10 mt-2 capitalize disabled" v-if="!edit">
                {{ getSlug(payFrequency) }}
              </div>
              <c-select placeholder="--Select--" variant="w-full h-10" :options="salaried" @onChange="handleFreq"
                v-model="payScheduleId" v-else />
              <small v-if="!edit && payType === 'salaried' && salaried.length < 1" class="text-flame font-extrabold">You
                need
                to set up your pay frequencies in pay settings.</small>
            </div>
            <div class="col-span-3">
              <label class="text-sm flex text-jet">
                <span>Annual Gross Pay</span>
              </label>
              <div class="wrapper h-10 mt-2 disabled" v-if="!edit">
                {{ accountGross }}
              </div>
              <c-text placeholder="--Enter--" variant="w-full h-10" type="number" v-model="accountGross" v-else />
            </div>
            <div v-if="payType === 'Hourly'" class="col-span-3">
              <div class="text-sm text-jet">Rate Per Hour</div>
              <div class="wrapper h-10 mt-2 disabled" v-if="!edit">
                {{ ratePerHour }}
              </div>
              <c-text placeholder="--Enter--" variant="w-full h-10" v-model="ratePerHour" v-else />
            </div>
            <div v-if="payType === 'Hourly'" class="col-span-3">
              <div class="text-sm text-jet">Max. No. of Hours Per Day</div>
              <div class="wrapper h-10 mt-2 disabled" v-if="!edit">
                {{ hoursPerDay }}
              </div>
              <c-text placeholder="--Enter--" variant="w-full h-10" v-model="hoursPerDay" v-else />
            </div>
            <div v-if="payType === 'Hourly'" class="col-span-3">
              <div class="text-sm text-jet">No. of Weeks Per Year</div>
              <div class="wrapper h-10 mt-2 disabled" v-if="!edit">
                {{ weeksPerYear }}
              </div>
              <c-text placeholder="--Enter--" variant="w-full h-10" v-model="weeksPerYear" v-else />
            </div>
            <div v-if="payType === 'Hourly'" class="col-span-3">
              <div class="text-sm text-jet">Estimated Annual Gross</div>
              <div class="wrapper h-10 mt-2 disabled">
                {{ estimatedAnnual || "Autoloaded" }}
              </div>
            </div>
          </div>
        </div>
        <divider class="my-8" />
        <div>
          <p class="font-bold text-lg mb-7">Account Information</p>
          <div class="grid grid-cols-12 gap-6 text-darkPurple" style="margin-top: 1.125rem">
            <div class="col-span-3 my-auto">
              <p class="font-semibold inline-block">Bank Information</p>
            </div>
            <div class="col-span-3">
              <c-select placeholder="--Enter--" :options="banks" variant="w-full" label="Bank"
                @input="accountInformation.accountNo = null" v-model="accountInformation.bank" :disabled="!edit" />
            </div>
            <div class="col-span-3">
              <c-text placeholder="--Enter--" label="Account Number" v-model="accountInformation.accountNo"
                type="number" :rules="numberRule" :disabled="!edit || disableNumber || !accountInformation.bank
                  " />
              <small v-if="loadingAccount" class="text-xs">Loading...</small>
              <small class="text-flame font-bold" v-if="bankError">{{
                `⚠️ ${bankError}`
              }}</small>
              <small class="text-mediumSeaGreen font-bold" v-if="accountInformation.accountName">{{ `
                ${accountInformation.accountName} ✅ ` }}</small>
            </div>
            <div class="col-span-3">
              <c-text placeholder="--Enter--" variant="w-full h-10" label="BVN" v-model="accountInformation.bvn"
                :disabled="!edit" />
            </div>
            <div class="col-span-3 my-auto">
              <p class="font-semibold mb-4 inline-block">Tax Information</p>
            </div>
            <div class="col-span-3">
              <c-select placeholder="--Enter--" :options="states" variant="w-full" label="SIRS"
                v-model="accountInformation.sirs" :disabled="!edit" />
            </div>
            <div class="col-span-3">
              <c-text placeholder="--Enter--" label="Employee Tax ID" v-model="accountInformation.employeeTaxId"
                :disabled="!edit" />
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 text-darkPurple" style="margin-top: 1.125rem">
            <div class="col-span-3 my-auto">
              <p class="font-semibold mb-4 inline-block">Pension Information</p>
            </div>
            <div class="col-span-3">
              <c-select placeholder="--Enter--" :options="pencom" variant="w-full" label="PFA"
                v-model="accountInformation.pfaId" :disabled="!edit" />
            </div>
            <div class="col-span-3">
              <c-text placeholder="--Enter--" label="RSA PIN" v-model="accountInformation.rsaId" :disabled="!edit" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="showFunctionModal">
      <Card class="flex px-4 flex-col max-w-md" style="width: 490px">
        <div class="w-full py-4" style="border-bottom: 1px dashed #878e99">
          <p class="font-bold">{{ isEditFunction ? 'Edit Department' : 'New Department' }}</p>
        </div>
        <div class="col-span-3 mt-4">
          <c-text placeholder="--Enter--" v-model="newFunction" variant="w-full" label="Department" />
        </div>
        <div class="flex my-6 justify-end">
          <Button background-color="none" class="text-flame" width="96px" border="1px solid #F15A29"
            :disabled="disableBtn || isSubmitting" @click="onClose()">
            Cancel
          </Button>
          <Button width="96px" :disabled="disableBtn || isSubmitting" class="ml-4 text-white"
            :background="appearance.buttonColor || defaultBTNColor"
            @click="isEditFunction ? editFunction() : saveFunction()">
            {{ isSubmitting ? 'Saving...' : 'Save' }}
          </Button>
        </div>
      </Card>
    </Modal>

    <Modal v-if="showWorkModelModal">
      <Card class="flex px-4 flex-col max-w-md" style="width: 490px">
        <div class="w-full py-2" style="border-bottom: 1px dashed #878e99">
          <p class="font-bold">Hybrid</p>
        </div>
        <div class="col-span-3 mt-4">
          <radio-button :options="daysType" col-span="col-span-4" class="inline-block text-darkPurple"
            @change="handleHybridChange" v-model="employment.hybridTypes" />
        </div>
        <div v-if="employment.hybridTypes">
          <div v-if="employment.hybridTypes === 'noOfDays'" class="flex mt-5">
            <div>
              <c-text placeholder="--Enter--" v-model="employment.customDaysRemote" variant="w-full" type="number"
                label="Remote" />
            </div>
            <div class="ml-4">
              <c-text placeholder="--Enter--" v-model="employment.customDaysOnSite" variant="w-full" type="number"
                label="On-Site" />
            </div>
          </div>
          <p v-if="employment.hybridTypes === 'weekDays'" class="mt-5">
            Remote
          </p>
          <div v-if="employment.hybridTypes === 'weekDays'" class="flex flex-wrap mt-2 gap-3">
            <card v-for="(day, index) in weekDays" :key="index" class="mr-2 p-2 text-sm" style="width: 27%">
              <checkbox :label="day" checkbox-size="height:16px; width:16px;
                margin-top: 2px;" class="capitalize" :value="day" v-model="employment.weekDaysRemote" />
            </card>
          </div>
        </div>
        <div v-if="employment.hybridTypes" :class="employment.hybridTypes === 'noOfDays' ? 'flex' : ''">
          <p v-if="employment.hybridTypes === 'weekDays'" class="mt-5">
            On-Site
          </p>
          <div v-if="employment.hybridTypes === 'weekDays'" class="flex flex-wrap mt-2 gap-3">
            <card v-for="(day, index) in weekDays" :key="index" class="mr-2 p-2 text-sm" style="width: 27%">
              <checkbox :label="day" checkbox-size="height:16px; width:16px;
                margin-top: 2px;" class="capitalize" :value="day" v-model="employment.weekDaysOnSite" />
            </card>
          </div>
        </div>
        <div class="flex my-6 justify-end">
          <Button background-color="none" class="text-flame" width="96px" border="1px solid #F15A29"
            @click="showWorkModelModal = false">
            Cancel
          </Button>
          <Button width="96px" class="ml-4 text-white" :background="appearance.buttonColor || defaultBTNColor"
            @click="saveWorkModel">
            Continue
          </Button>
        </div>
      </Card>
    </Modal>
    <Modal v-if="showDesignationModal">
      <Card class="flex px-4 flex-col max-w-md" style="width: 490px">
        <div class="w-full py-4" style="border-bottom: 1px dashed #878e99">
          <p class="font-bold">{{ isEditDesignation ? 'Edit Job Title' : 'New Job Title' }}</p>
        </div>
        <div class="col-span-12 mt-4">
          <p class="font-semibold text-darkPurple">
            Which of these two best describe the Job Title?
          </p>
          <radio-button :options="categoryArray" col-span="col-span-6" class="m-4 text-darkPurple" row-gap="gap-y-5"
            v-model="isSupervisory" />
        </div>
        <div class="col-span-3 mt-2">
          <c-text placeholder="--Enter--" variant="w-full" :label="isBasic ? 'Job Title' : 'Designation'"
            v-model="newDesignation" />
        </div>
        <div class="col-span-12 mt-2">
          <c-select placeholder="--Select--" :options="designationsArray" variant="w-full" label="Reporting line"
            v-model="reportingTo" />
        </div>
        <div class="flex my-6 justify-end">
          <Button background-color="none" class="text-flame" width="96px" border="1px solid #F15A29"
            @click="(showDesignationModal = false), (newDesignation = '')">
            Cancel
          </Button>
          <Button width="96px" :disabled="disableBtn" class="ml-4 text-white"
            :background="appearance.buttonColor || defaultBTNColor" @click="saveDesignation">
            Save
          </Button>
        </div>
      </Card>
    </Modal>
    <Modal v-if="showConfirmationModal">
      <Card class="flex px-4 flex-col max-w-md" style="width: 490px">
        <div class="w-full py-4" style="border-bottom: 1px dashed #878e99">
          <p class="font-extrabold text-xl">Confirm Action</p>
        </div>
        <h6>Do you want to confirm this employee?</h6>
        <div class="mb-12 -mt-4">
          <alert
            message="Confirming an employee through this action is irreversible. However, if you choose not to confirm this employee now, you can only do so later through the probation/confirmation workflow. Kindly proceed to select 'Yes', If this employee is already confirmed."
            variant="primary" :time="2" style="
              position: relative;
              right: 0;
              top: 35px;
              border: 1px solid #e99323;
              box-shadow: none;
              background: rgba(244, 213, 176, 0.15);
              color: black;
            " />
        </div>
        <div class="col-span-3 mt-4">
          <radio-button :options="yesOrNo" col-span="col-span-4" disable class="inline-block text-darkPurple"
            v-model="employment.employmentStatus" />
        </div>
        <div class="flex my-6 justify-end">
          <Button background-color="none" class="text-flame" width="96px" border="1px solid #F15A29"
            @click="showConfirmationModal = false">
            Cancel
          </Button>
          <Button width="96px" :disabled="disableBtn" class="ml-4 text-white"
            :background="appearance.buttonColor || defaultBTNColor" @click="showConfirmationModal = false">
            Apply
          </Button>
        </div>
      </Card>
    </Modal>
    <RightSideBar v-if="openSettingsModal" @submit="saveSettings" submit="Save" @close="openSettingsModal = false"
      button-class="bg-dynamicBackBtn text-white">
      <template v-slot:title>
        <p class="text-darkPurple">Settings Preferences</p>
      </template>
      <div class="mb-8 -mt-8">
        <alert message="Let us know how you would prefer to treat this employee’s overtime settings." variant="primary"
          :time="2" style="
            position: relative;
            right: 0;
            top: 35px;
            border: 1px solid #e99323;
            box-shadow: none;
            background: rgba(244, 213, 176, 0.15);
            color: black;
          " />
      </div>
      <div class="mt-16">
        <checkbox v-model="employment.inheritGeneralOvertimeSetting"
          label="Allow changes in general settings to override employee  overtime settings"
          label-style=" margin-left: 8px" checkbox-size="width:16px; height:16px; margin: 2px 4px 0 0;" />
      </div>
    </RightSideBar>

    <c-modal v-if="isOpenDeleteFuncDesig">
      <c-card class="flex flex-col gap-5 max-w-max py-3 px-5">
        <p class="text-jet text-base font-bold border-b border-solid border-romanSilver">
          Action Required!
        </p>
        <div class="flex flex-col justify-start items-start gap-0">
          <p class="text-jet text-base font-semibold">
            Do you want to delete this {{ deleteModalType }}?
          </p>
        </div>
        <div class="w-full flex justify-center gap-4">
          <c-button variant="primary c-button" :disabled="isSubmitting" @onClick="onDeleteFuncDesig()"
            :label="isSubmitting ? 'Deleting...' : 'Delete'" />
          <c-button variant="secondary c-button" label="Cancel" :disabled="isSubmitting"
            @onClick="isOpenDeleteFuncDesig = false" />
        </div>
      </c-card>
    </c-modal>
  </div>
</template>

<script>
import * as _ from "lodash";
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapState } from "vuex";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import Divider from "@/components/divider";
import CModal from "@/components/Modal"
import organisationMixin from "@/utilities/organisationMixin";
import PayInfoMixin from "./payInformationMixin";


export default {
  name: "EmploymentDetails",
  components: {
    CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
    Datepicker: () => import("vuejs-datepicker"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    RightSideBar: () => import("@/components/RightSideBar"),
    vSelect: () => import("vue-select"),
    Modal: () => import("@/components/Modal"),
    Card: () => import("@/components/Card"),
    Button: () => import("@/components/Button"),
    RadioButton: () => import("@/components/RadioButton"),
    Checkbox: () => import("@/components/Checkbox"),
    Alert,
    Divider,
    VSelect,
    CButton,
    CModal,
    CCard
  },
  props: {
    cannotEdit: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [PayInfoMixin, organisationMixin],

  data() {
    return {
      edit: false,
      isEditFunction: false,
      isEditDesignation: false,
      isSubmitting: false,
      isOpenDeleteFuncDesig: false,
      hoveredItem: {},
      employmentDate: "",
      employmentTypes: [],
      openSettingsModal: false,
      showConfirmationModal: false,
      allowGeneralSettings: false,
      isLoadingReportingTo: false,
      designationId: null,
      activeDesignation: {},
      allOrgUsers: [],
      loading: true,
      locationData: [],
      newFunction: "",
      functionId: null,
      isSupervisory: false,
      reportingTo: null,
      designationsArray: [],
      newDesignation: "",
      isConfirmed: null,
      disableBtn: false,
      showFunctionModal: false,
      showDesignationModal: false,
      showWorkModelModal: false,
      deleteModalType: '',
      categoryArray: [
        { name: "Supervisory", radioName: "designation", value: true },
        { name: "Non-Supervisory", radioName: "designation", value: false },
      ],
      daysType: [
        {
          name: "No of Days",
          radioName: "days",
          value: "noOfDays",
        },
        { name: "Week Days ", radioName: "days", value: "weekDays" },
      ],
      yesOrNo: [
        {
          name: "Yes",
          radioName: "confirm",
          value: "confirmed",
        },
        {
          name: "No",
          radioName: "confirm",
          value: "probation",
        },
      ],
      overtimeOptions: [
        { name: "Yes", id: 'true' },
        { name: "No", id: 'false' },
      ],
      workModelOptions: ["Fully On-Site", "Fully Remote", "Hybrid", "Deskless"],
      showHybridType: null,
      employment: {
        employeeId: "",
        employmentStatus: null,
        employmentType: "",
        isActive: null,
        functionId: null,
        enabledOvertime: false,
        inheritGeneralOvertimeSetting: false,
        hybridTypes: null,
        customDaysRemote: null,
        customDaysOnSite: null,
        weekDaysRemote: [],
        weekDaysOnSite: [],

        workModel: null,
        level: null,
        designation: null,
        reportingTo: null,
        dottedReportingTo: "",
        resumptionDate: "",
        officeAddress: "",
      },
      primaryStatus: [
        { name: "Active", id: "true" },
        { name: "In active", id: "false" },
      ],
      status: [
        {
          name: "On Probation",
          id: "conditional",
        },
        {
          name: "Confirmed",
          id: "confirmed",
        },
        {
          id: "suspended",
          name: "Suspended",
        },
        {
          id: "disciplinary_hearing",
          name: "Disciplinary Hearing",
        },
        {
          id: "terminated",
          name: "Terminated",
        },
        {
          id: "dismissed",
          name: "Dismissed",
        },
        {
          id: "resigned",
          name: "Resigned",
        },
        {
          id: "long_absence",
          name: "Long Absence",
        },
        {
          id: "deceased",
          name: "Deceased",
        },
      ],
      selectedDays: [],
      weekDays: [
        "monday",

        "tuesday",

        "wednesday",

        "thursday",

        "friday",

        "saturday",

        "sunday",
      ],
    };
  },
  computed: {
    isBasic() {
      return this.$store.state.subscription && this.$store.state.subscription.plan === 'basic'
    },
    isStandard() {
      return this.$store.state.subscription && this.$store.state.subscription.plan === 'standard'
    },
    accountGross: {
      get() {
        if (this.annualGross) {
          if (!this.edit) return this.annualGross
          return Number(this.annualGross.split(",").join(""))
        }
        return null
      },
      set(val) {
        this.annualGross = val
      }
    },

    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  watch: {
    globalDesignation(nextState, prevState) {
      /* enabledOvertime set automatically when designation changes. This check to make sure designation does not set enabledOvertime on mounted.
      At mounted, prevState is null and nextState has an ID. This triggers the watcher prematurely and set enabledOvertime from designation.
      The check is to make sure enabledOvertime changes only when designation changes and does not trigger the watcher immediately on mounted
      */
      if (!_.isNull(prevState) && !_.isNull(nextState)) {
        const { designations } = this.setDesignation;
        const { enabledOvertime } = _.find(designations, (el) => el.id === nextState);
        this.employment.enabledOvertime = !_.isNull(enabledOvertime) ? JSON.stringify(enabledOvertime) : 'false';
      }
    },
  },
  methods: {
    handleHybridChange(value) {
      this.showHybridType = value;
    },
    handleWorkModelChange(value) {
      if (value === "Hybrid") {
        this.showWorkModelModal = true;
      }
    },
    saveWorkModel() {
      this.showWorkModelModal = false;
    },
    onModifyDesignation(designation) {
      this.activeDesignation = designation;
      this.newDesignation = designation.name
      this.isSupervisory = designation.isSupervisory
      this.reportingTo = designation.reportingTo
      this.isEditDesignation = true
      this.showDesignationModal = true
    },
    onClose() {
      this.isSubmitting = false
      this.isOpenDeleteFuncDesig = false
      this.showFunctionModal = false
      this.showDesignationModal = false
      this.showWorkModelModal = false
      this.disableBtn = false;
    },
    onDeleteFuncDesig(deleteModalType) {
      if (!this.isOpenDeleteFuncDesig) {
        this.isOpenDeleteFuncDesig = true
        this.deleteModalType = deleteModalType
      } else {
        this.isSubmitting = true

        if (this.deleteModalType === 'function') {
          this.$_deleteFunction(this.globalFunctionId).then(async () => {
            this.$toasted.success('Department deleted successfully', { duration: 5000 })
            await this.getSupDesignation()
            await this.$store.dispatch("org/getFunction");
            this.onClose()
          }).catch((error) => {
            this.$toasted.error(error, { duration: 5000 })
            this.isSubmitting = false
          })
        } else if (this.deleteModalType === 'designation') {
          // const {functionId} = this.employment
          this.$_deleteDesignation([this.globalDesignation]).then(async () => {
            this.$toasted.success('Job title deleted successfully', { duration: 5000 })
            await this.$store.dispatch("org/getFunction");
            this.onClose()
          }).catch((error) => {
            this.$toasted.error(error, { duration: 5000 })
            this.isSubmitting = false
          })
        }
      }
    },
    async saveDesignation() {
      this.isSubmitting = true
      this.disableBtn = true;
      if (this.reportingTo === 'null') {
        this.reportingTo = null;
      }
      const payload = {
        orgId: this.$AuthUser.orgId,
        createdBy: this.$AuthUser.id,
        name: this.newDesignation,
        isSupervisory: this.isSupervisory,
        reportingTo: this.reportingTo,
        functionId: this.globalFunctionId,
        id: this.activeDesignation.id
      };

      if (this.globalFunctionId && this.isEditDesignation) {
        this.$_updateDesignation(payload).then(() => {
          this.miniSave({ designation: this.activeDesignation.id })
          const message = "Job title updated successfully!"
          this.$toasted.success(message, { duration: 5000 })
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error, { duration: 5000 })
          this.isSubmitting = false
        })
      } else {
        this.$_createDesignation(payload).then(({ data }) => {
          this.miniSave({ designation: data.designation.id })
          const message = "Job title created successfully!"
          this.$toasted.success(message, { duration: 5000 })
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error, { duration: 5000 })
          this.isSubmitting = false
        })
      }
    },
    saveFunction() {
      this.isSubmitting = true

      const payload = {
        orgId: this.$orgId,
        name: this.newFunction,
        createdBy: this.$AuthUser.id,
      };
      this.$_createFunction(payload).then(({ data }) => {
        this.newFunction = "";
        this.showFunctionModal = false;
        this.miniSave({ functionId: data.orgFunction.id });
        const message = "Department created successfully";
        this.$toasted.success(data.message || message, { duration: 3000 });
        this.onClose()
      }).catch((error) => {
        this.$toasted.error(error.response.data.message, { duration: 3000 });
        this.isSubmitting = false
        throw new Error(error);
      });
    },
    editFunction() {
      this.isSubmitting = true

      const payload = {
        functionId: this.functionId,
        name: this.newFunction,
        costCenter: null,
        hierarchyId: null,
        kpis: [],
        reportingTo: null
      };
      this.$_editFunction(payload)
        .then(async () => {
          this.newFunction = "";
          this.functionId = null
          this.isEditFunction = false
          this.showFunctionModal = false;
          await this.getSupDesignation()
          await this.$store.dispatch("org/getFunction");
          this.$toasted.success("Department updated successfully", {
            duration: 5000
          });
          this.isSubmitting = false
        }).catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
          this.isSubmitting = false
          throw new Error(error);
        });
    },
    async handleClick() {
      try {
        await this.$handlePrivilege(
          "employeeDirectory",
          "editEmployeeEmploymentDetails"
        );
        if (this.isBasic && this.salaried.length < 1) {
          return this.$toasted.info("You have to setup your pay schedule to fill employment details", {
            duration: 10000,
            action: [
              {
                text: 'Setup now',
                onClick: (e, toastObject) => {
                  this.$router.push({ name: 'PaySettings' })
                  toastObject.goAway(0);
                }
              },
              {
                text: 'Cancel',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                }
              }
            ]
          });
        }
        this.edit = !this.edit;
        return this.edit
      } catch (error) {
        return this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async setAllOrgUsers(search, loading) {
      loading(true);
      try {
        const { data } = await this.$_getAllOrgUsers(`search=${search}`);
        this.allOrgUsers = data;
        loading(false);
      } catch (err) {
        throw new Error(err);
      }
    },
    async miniSave(type) {
      try {
        this.employment.containsBio = true;
        this.employment.containsEmploymentInfo = true;
        this.employment.employmentDate = this.employmentDate;
        await this.$_editEmployeeByType({
          id: this.$route.params.id,
          type: "employment-info",
          payload: type,
        });
        if (type.functionId) {
          // this.employment.functionId = type.functionId;
          this.globalFunctionId = type.functionId
        } else {
          // this.employment.designation = type.designation;
          this.globalDesignation = type.designation;
        }
        await this.getSupDesignation()
        await this.$store.dispatch("org/getFunction");
        this.onClose()
      } catch (error) {
        this.$toasted.error("Employment information not updated", {
          duration: 3000,
        });
      }
    },
    async handleSave() {
      if (this.employment.isActive === "true") {
        this.employment.isActive = true;
      }
      if (this.employment.isActive === "false") {
        this.employment.isActive = false;
      }
      if (this.employment.reportingTo === "null") {
        this.employment.reportingTo = null;
      }
      if (this.setLevel) {
        this.employment.level = this.setLevel.id;
      } else {
        this.employment.level = null;
      }
      try {
        this.employment.containsBio = true;
        this.employment.containsEmploymentInfo = true;
        this.employment.employmentDate = this.employmentDate;
        this.employment.functionId = this.globalFunctionId;
        this.employment.designation = this.globalDesignation;
        this.employment.enabledOvertime = JSON.parse(
          this.employment.enabledOvertime
        );
        this.employment.reportingTo = this.globalReportingTo;

        if (this.isBasic) {
          await this.saveAnnualGross()
          if (this.accountInformation.accountNo) {
            await this.saveBankInfo()
          }
          await this.saveTaxInfo()
          await this.savePensionInfo()
        }

        const payload = {
          id: this.$route.params.id,
          type: "employment-info",
          payload: this.employment,
        };

        this.$_editEmployeeByType(payload).then(() => {
          const message = "Employment details, updated successfully!";
          this.$toasted.success(message, { duration: 3000 });
          this.$store.dispatch(
            "employee/getOneEmployeeEmployementInfo",
            { id: this.$route.params.id }
          );
          this.edit = !this.edit;
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 });
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    getEmploymentInfo() {
      this.$_getOneEmployeeEmploymentInfo({
        id: this.$route.params.id,
      })
        .then(({ data }) => {
          this.employment = {
            ...data,
          officeAddress: data?.employeeOffice?.id};

          if (this.employment.employmentStatus === "confirmed") {
            this.isConfirmed = true;
          }

          this.globalFunctionId = this.employment.orgFunction ? this.employment.orgFunction.id : null;
          this.globalDesignation = this.employment.userDesignation ? this.employment.userDesignation.id : null;
          this.employmentDate = this.employment.employmentDate ? this.employment.employmentDate : null;
          this.$_getAllOrgUsers(
            `search=${this.employment.userReportingTo
              ? this.employment.userReportingTo.fname
              : null
            }`
          ).then((result) => {
            this.allOrgUsers = result.data;
            this.loading = false;
          });
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    editInfo() {
      this.isDisabled = false;
      this.isEdit = false;
    },
    getHumanarEmploymentTypes() {
      this.$_getHumanarEmploymentTypes().then(({ data }) => {
        this.employmentTypes = data.result.map((item) => item.name);
      });
    },
    saveSettings() {
      const payload = {
        inheritGeneralOvertimeSetting:
          this.employment.inheritGeneralOvertimeSetting,
      };
      this.$_saveSettingPreference(this.$route.params.id, payload)
        .then(() => {
          this.openSettingsModal = false;
          this.getEmploymentInfo();
          this.$toasted.success("Settings saved successfully", {
            duration: 5000,
          });
        })
        .catch(() => {
          this.openSettingsModal = false;
          this.$toasted.error(
            "Settings not saved please contact your administrator",
            { duration: 5000 }
          );
        });
    },
  },
  async mounted() {
    this.getHumanarEmploymentTypes();
    this.$_getHumanarLocations().then((result) => {
      this.getEmploymentInfo();
      this.locationData = result.data.outlets;
    });
  },
};
</script>

<style>
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}

.c-button {
  width: 100% !important;
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
